<template>
  <div :class="`d-flex ${flexDirection}`">
    <!-- {{userList}} -->
    <slot name="prepend" />
    <slot
      name="selected-user"
      :userList="userList"
    >
      <div :class="`d-flex flex-wrap flex-${chipsDirection}`">
        <user-chip
          v-for="(user, index) in userList"
          :key="getUserKey(user)"
          v-bind:[isDraggable]="'draggable'"
          :attach="attach"
          :user.sync="userList[index]"
          @update:user="updateList"
          :close="canCloseChip && !readonly"
          :required-check-box="requiredCheckBox"
          :warn-no-consultation="warnNoConsultation(user)"
          :readonly="readonly"
          :hide-email-if-user="hideEmailIfUser"
          v-on="onChip(user)"
          @click:close="removeUser(user)"
          @apply="$emit('apply', $event)"
          class="mr-2"
          :external-user-cache-type="externalUserCacheType"
        />
        <slot
          name="user-picker"
          :userList="userList"
        >
          <picker
            :attach="attach"
            hide-label
            :selected-id.sync="newSelectedUserId"
            item-text="fullname"
            :document-types="documentTypes"
            :hidden="documentTypes"
            :placeholder="placeholder"
            :search-input.sync="computedSearchText"
            :excluded-items="_excludedUsers"
            :filters="filters"
            :rules="rules"
            :scope="scope"
            :has-email="hasEmail"
            class="mt-1 d-flex min-width"
            :class="{'mr-4': !multiple, 'full-width': !multiple}"
            reset-on-select
            v-if="!readonly && showPicker && (multiple || (!multiple && !userList.length))"
            ref="picker-user"
          >
            <template
              v-slot:append-outer
              v-if="canAddUserEmail"
            >
              <v-btn
                icon
                small
                @click="addUserEmail"
              >
                <icon-tooltip
                  color="primary"
                  icon-name="i.AddEmail"
                  :tooltip-text="$t('t.AddRecipient')"
                />
              </v-btn>
            </template>
            <template v-slot:append>
              <slot name="append" />
            </template>
          </picker>
        </slot>
      </div>
    </slot>

  </div>
</template>

<script>
import { getDoc, CacheType } from '@/wasm/pkg'
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    UserChip: () => import('@/components/user-chip'),
    Picker: () => import('@/components/picker'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data: function () {
    return {
      newSelectedUserId: null,
      selectedUser: null,
      searchText: ''
    }
  },
  watch: {
    newSelectedUserId: {
      immediate: true,
      async handler (n, o) {
        if (!o && n) {
          const user = await getDoc(CacheType.UserRef, n)
          const newUser = Object.assign({}, user, { fullName: user.fullname, email: user.email, isRequired: false })

          this.userList = this.multiple ? [...this.userList, newUser] : [newUser]

          this.$nextTick(() => { this.newSelectedUserId = null })
        }
      }
    }
  },
  computed: {
    _excludedUsers () {
      return this.userList.concat(this.excludedUsers)
    },
    _prependProps () {
      const _ = Object.assign({
        left: false,
        top: true,
        right: false,
        bottom: false
      }, this.prependProps)

      return _
    },
    canAddUserEmail () {
      if (!this.showAddUserEmail || !this.searchText || this.searchText.length < 5) {
        return false
      }
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regexp.test(this.searchText) && this.userList.findIndex(i => i.email === this.searchText) < 0
    },
    flexDirection () {
      const d = this._prependProps.left || this._prependProps.right
        ? 'row'
        : 'column'

      const r = this._prependProps.right || this._prependProps.bottom
        ? '-reverse'
        : ''

      return `flex-${d}${r}`
    },
    computedSearchText: {
      get () {
        return this.searchText
      },
      set (v) {
        this.searchText = v
      }
    },
    isDraggable () {
      return this.isChipDraggable ? 'draggable' : null
    },
    multiple () {
      return Array.isArray(this.selection)
    },
    userList: {
      get () {
        return this.lodash.cloneDeep(Array.isArray(this.selection) ? this.selection : (this.selection ? [this.selection] : [])).map(s => Object.assign(s, { id: s.userId ?? s.id }))
      },
      set (v) {
        this.$emit('update:selection', this.multiple ? v : (v ? v[0] : v))
      }
    }
  },
  methods: {
    getUserKey (user) {
      return user.id ?? user.userId
    },
    updateList () {
      this.$emit('update:selection', this.multiple ? this.userList : (this.userList ? this.userList[0] : this.userList))
    },
    addUserEmail () {
      const clonedUserList = this.lodash.cloneDeep(this.userList)
      clonedUserList.push({ email: this.searchText })
      this.userList = clonedUserList

      const picker = this.$refs['picker-user']
      picker.closeMenu()
      picker.$refs.autocomplete.setSearch()
    },
    removeUser (user) {
      const indexUser = this.lodash.findIndex(this.userList, item => item.id === user.id && item.email === user.email)
      const clonedUserList = this.lodash.cloneDeep(this.userList)
      clonedUserList.splice(indexUser, 1)
      this.userList = clonedUserList
    }
  },
  props: {
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    externalUserCacheType: Number,
    chipsDirection: {
      type: String,
      default: 'row'
    },
    documentTypes: {
      required: true,
      type: [Array, String]
    },
    filters: {
      type: Object,
      default: undefined
    },
    hasEmail: {
      type: Boolean,
      default: undefined
    },
    hideEmailIfUser: Boolean,
    selection: {
      type: [Array, Object]
    },
    //  selectedIds: {
    //   type: [Array, Object]
    // },
    placeholder: String,
    scope: Scope,
    prependProps: Object,
    onChip: {
      type: Function,
      default: () => { }
    },
    canCloseChip: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isChipDraggable: {
      type: Boolean,
      default: false
    },
    showPicker: {
      type: Boolean,
      default: true
    },
    showAddUserEmail: Boolean,
    requiredCheckBox: Boolean,
    warnNoConsultation: {
      type: Function,
      default: () => false
    },
    excludedUsers: {
      type: Array,
      default: () => []
    },
    rules: Array
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%

.min-width
  min-width 20vw

.user-picker
  flex-wrap wrap
</style>
